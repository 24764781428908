import axios from "~/plugins/axios";
export default {
  getGType(result, type) {
    for (var i = 0; i < result.address_components.length; i++) {
      for (var j = 0; j < result.address_components[i].types.length; j++) {
        if (result.address_components[i].types[j] == type) {
          return result.address_components[i].short_name;
        }
      }
    }
    return null;
  },
  decodeGoogleAddress(result) {
    var address = {};
    if (result.address_components) {
      address.address1 = this.getGType(result, "route");
      address.addressNumber = this.getGType(result, "street_number");
      address.postalcode = this.getGType(result, "postal_code");
      address.city = this.getGType(result, "locality");
      if (!address.city) {
        address.city = this.getGType(result, "administrative_area_level_3");
      }
      address.province = this.getGType(result, "administrative_area_level_2");
      address.latitude = result.geometry.location.lat;
      address.longitude = result.geometry.location.lng;
      address.gpsLatitude = result.geometry.location.lat;
      address.gpsLongitude = result.geometry.location.lng;
      return address;
    } else {
      return null;
    }
  },
  composeAddress(addressData, addressValue) {
    var addressParts = [];
    if (addressValue) {
      addressParts.push(addressValue);
    } else {
      if (addressData.address1) addressParts.push(addressData.address1);
    }
    if (addressData.addressNumber != null)
      addressParts.push(addressData.addressNumber);
    if (addressData.postalcode != null)
      addressParts.push(addressData.postalcode);
    if (addressData.city) addressParts.push(addressData.city);
    if (addressData.province != null) addressParts.push(addressData.province);
    var currentAddress = addressParts.join(" , ");
    return currentAddress;
  },
  autocompleteAddress(address) {
    return (
      axios.get("https://maps.googleapis.com/maps/api/place/autocomplete/json"),
      {
        params: {
          input: address,
          key: global.config.google_api_key,
          types: "address",
          components: global.config.googleGeocodeParams.components
        }
      }.then(response => {
        return response;
      })
    );
  },
  geocodeAddress(address) {
    var ad = this.composeAddress(address);
    return axios
      .get("https://maps.googleapis.com/maps/api/geocode/json", {
        params: {
          address: ad,
          key: global.config.google_api_key,
          components: global.config.googleGeocodeParams.components
        }
      })
      .then(response => {
        if (response.data && response.data.results.length === 0) {
          if (response.data.error_message) {
            global.EventBus.$emit("error", {
              error: "err",
              message: response.data.error_message
            });
          }
          return null;
        } else {
          return this.decodeGoogleAddress(response.data.results[0]);
        }
      })
      .catch(err => {
        console.log(err); //Axios entire error message
        console.log(err.response.data.error); //Google API error message
      });
  },
  lookupCities(dataAddress) {
    return this.lookup(dataAddress, "administrative_area_level_3,locality");
  },
  lookupStreets(dataAddress, addressValue) {
    return this.lookup(dataAddress, "route", addressValue);
  },
  async lookup(address, filters) {
    const response = await axios.get(
      "https://maps.googleapis.com/maps/api/geocode/json",
      {
        params: {
          address:
            typeof dataAddress === "object"
              ? this.composeAddress(address)
              : address,
          key: global.config.google_api_key,
          components: global.config.googleGeocodeParams.components
        }
      }
    );

    // console.log(response);

    var results = response.data.results;
    var deferredResults = [];

    if (filters) {
      filters = filters.split(",");
      for (var i = 0; i < results.length; i++) {
        var result = results[i];
        for (var f = 0; f < filters.length; f++) {
          if (result.types && result.types.indexOf(filters[f]) > -1) {
            deferredResults.push(this.decodeGoogleAddress(result));
            break;
          }
        }
      }
    } else {
      deferredResults = results;
    }

    return Promise.resolve(deferredResults);
  }
};
