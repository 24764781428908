<template>
  <v-container
    class="service-autodiscovery-container default--text text--darken-1 pb-12"
  >
    <h2 class="font-weight-bold">{{ $t("addresses.autoDiscovery.title") }}</h2>
    <div class="text-body-2">
      {{ $t("addresses.autoDiscovery.description") }}
    </div>

    <div class="vue-autocomplete-container d-flex gap-2 mt-4">
      <vue-google-autocomplete
        id="map"
        classname="service-discovery"
        class="rounded-sm"
        ref="address"
        :placeholder="$t('addresses.autoDiscovery.placeholder')"
        :value="baseAddressString"
        :enable-geolocation="true"
        country="it"
        v-if="googleEnabled"
        @placechanged="getAddressData"
      >
        <!-- @keydown.native.enter.prevent="searchFirst" -->
      </vue-google-autocomplete>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            title="Geolocalizzami"
            class="rounded-sm primary white--text"
            fab
            color="primary"
            icon
            small
            @click="geolocate"
            v-bind="attrs"
            v-on="on"
            ><v-icon>$location</v-icon></v-btn
          >
        </template>
        {{ $t("addresses.autoDiscovery.buttonTooltip") }}
      </v-tooltip>
    </div>

    <v-card v-if="results" class="results mt-6">
      <v-list>
        <v-subheader>
          <h3>
            {{ $t("addresses.autoDiscovery.deliveryService") }}
          </h3>
        </v-subheader>
        <v-list-item
          class="d-flex flex-column flex-sm-row justify-space-between"
        >
          <div class="d-flex align-self-start">
            <v-list-item-icon
              class="d-none d-sm-flex align-center service-icon-wrapper"
            >
              <img
                class="service-icon logo-home"
                :src="$t('navbar.service.2.icon')"
                alt="Coop Casa"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="auto-discovery-title"
                v-if="this.dataValidated.active == 1"
              >
                {{ $t("addresses.autoDiscovery.addressOk") }}
              </v-list-item-title>
              <v-list-item-title class="auto-discovery-title" v-else>
                {{ $t("addresses.autoDiscovery.addressKo") }}
              </v-list-item-title>
            </v-list-item-content>
          </div>
          <v-list-item-action class="align-self-end align-self-md-center">
            <v-btn
              v-if="dataValidated.active == 1"
              color="primary"
              depressed
              min-width="170"
              @click="handleAddressCreation(dataValidated)"
            >
              <span>
                {{ $t("addresses.autoDiscovery.addAddressButton") }}
              </span>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider class="d-flex d-sm-none my-4" />

        <v-subheader class="mt-2">
          <h3>
            {{ $t("addresses.autoDiscovery.driveService") }}
          </h3>
        </v-subheader>
        <v-list-item v-if="warehouses.length == 0" class="mb-8 mb-sm-auto">
          <v-list-item-icon
            class="d-none d-sm-flex align-center service-icon-wrapper"
          >
            <img
              class="service-icon logo-home"
              :src="$t('navbar.service.1.icon')"
              alt="Coop Drive"
            />
          </v-list-item-icon>
          <v-list-item-content>
            {{ $t("addresses.autoDiscovery.noStoresFound") }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          v-for="warehouse in warehouses"
          :key="warehouse.warehouseId"
          class="d-flex flex-column flex-md-row justify-space-between w-100"
        >
          <div class="d-flex align-self-start">
            <v-list-item-icon
              class="d-none d-sm-flex align-center service-icon-wrapper"
            >
              <img
                class="service-icon"
                :src="$t('navbar.service.1.icon')"
                alt="Logo drive"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="auto-discovery-title">
                {{
                  $t(
                    "navbar.address." +
                      warehouse.address.addressTypeId +
                      ".format",
                    warehouse.address
                  )
                }}
              </v-list-item-title>
              <v-list-item-subtitle class="auto-discovery-subtitle">
                {{
                  $t(
                    "navbar.address." +
                      warehouse.address.addressTypeId +
                      ".list",
                    warehouse.address
                  )
                }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </div>

          <v-list-item-action
            class="align-self-end align-self-md-center flex-row gap-2"
          >
            <v-btn
              v-if="warehouse && warehouse.slug"
              color="primary"
              outlined
              class="pl-3"
              :to="{
                name: 'WarehouseDetail',
                params: { warehouseSlug: warehouse.slug }
              }"
            >
              <v-icon small class="mr-1">$info</v-icon>
              {{ $t("addresses.autoDiscovery.discoverWarehouse") }}
            </v-btn>
            <v-btn
              color="primary"
              depressed
              :min-width="$vuetify.breakpoint.xs ? 'auto' : 170"
              @click="
                handleWarehouseClick(
                  warehouse.address.addressType,
                  warehouse.address.addressId
                )
              "
            >
              <span>
                {{ $t("addresses.autoDiscovery.start") }}
              </span>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-subheader class="mt-2">
          <h3>
            {{ $t("addresses.autoDiscovery.lockerService") }}
          </h3>
        </v-subheader>
        <v-list-item v-if="lockers.length == 0">
          <v-list-item-icon class="d-flex align-center service-icon-wrapper">
            <img
              class="service-icon logo-home"
              :src="$t('navbar.service.3.icon')"
              alt="Coop Locker"
            />
          </v-list-item-icon>
          <v-list-item-content>
            {{ $t("addresses.autoDiscovery.noLockersFound") }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          v-for="locker in lockers"
          :key="locker.warehouseId"
        >
          <v-list-item-icon
            class="d-none d-sm-flex align-center service-icon-wrapper"
          >
            <img
              class="service-icon locker-logo"
              :src="$t('navbar.service.3.icon')"
              alt="Logo locker"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="auto-discovery-title">
              {{
                $t(
                  "navbar.address." + locker.address.addressTypeId + ".format",
                  locker.address
                )
              }}
            </v-list-item-title>
            <v-list-item-subtitle class="auto-discovery-subtitle">
              {{
                $t(
                  "navbar.address." + locker.address.addressTypeId + ".list",
                  locker.address
                )
              }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-action
            class="align-self-end align-self-md-center flex-row gap-2"
          >
            <v-btn
              v-if="locker.slug"
              color="primary"
              outlined
              class="pl-3"
              :to="{
                name: 'WarehouseDetail',
                params: { warehouseSlug: locker.slug }
              }"
            >
              <v-icon small class="mr-1">$info</v-icon>
              {{ $t("addresses.autoDiscovery.discoverWarehouse") }}
            </v-btn>
            <v-btn
              color="primary"
              depressed
              min-width="170"
              @click="
                handleWarehouseClick(
                  locker.address.addressType,
                  locker.address.addressId
                )
              "
            >
              <span>
                {{ $t("addresses.autoDiscovery.start") }}
              </span>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-container>
</template>
<style lang="scss">
.service-autodiscovery-container {
  .v-list-item::after {
    display: none;
  }
  .v-list-item {
    border-bottom: 1px solid var(--v-grey-lighten2);
  }
  .v-list-item__action {
    min-width: fit-content;
  }
  .service-discovery {
    background-color: white;
    border: 1px solid $border-color;
    line-height: 20px;
    padding: 8px;
    width: 100%;
    border-color: rgba(0, 0, 0, 0.42);
    border-radius: 4px;
  }
  .service-icon-wrapper {
    width: 135px;
  }
  .service-icon {
    height: 30px;
    margin-top: 6px;
  }
  .add-address-icon {
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
  }
  .auto-discovery-title,
  .auto-discovery-subtitle {
    white-space: normal !important;
  }
}
</style>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import AddressService from "~/service/addressService";
import gmapsInit from "./gmaps";
import login from "~/mixins/login";
import { mapActions } from "vuex";
import GeoService from "~/service/geoService";
import includes from "lodash/includes";

var google;

export default {
  name: "ServiceAutodiscovery",
  components: { VueGoogleAutocomplete },
  mixins: [login],
  props: { additionalData: { type: Object, required: false } },
  data() {
    return {
      baseAddressString: null,
      warehouses: [],
      lockers: [],
      dataValidated: {},
      deliveryServiceAvailable: null,
      results: false,
      googleEnabled: false
    };
  },
  computed: {
    address() {
      return this.$t(
        "navbar.address." + this.dataValidated.addressTypeId + ".format",
        this.dataValidated
      );
    },
    getType() {
      return "(cities)";
    }
  },
  methods: {
    ...mapActions({ setAddress: "cart/setAddress" }),
    async getAddressData(addressData, placeResult) {
      placeResult.address_components.forEach(element => {
        if (element.types[0] == "country") {
          addressData.country = { iso: element.short_name };
          return;
        }
      });

      try {
        this.dataValidated = await AddressService.isValidAddress({
          country: addressData.country,
          city: addressData.locality,
          postalcode: addressData.postal_code,
          address1: addressData.route,
          addressNumber: addressData.street_number,
          gpsLongitude: addressData.longitude,
          gpsLatitude: addressData.latitude,
          longitude: addressData.longitude,
          latitude: addressData.latitude,
          province: this.getProvince(placeResult)
        });
        this.deliveryServiceAvailable = true;
      } catch (err) {
        this.deliveryServiceAvailable = false;
      }

      // lookup drive
      try {
        let res = await AddressService.findWarehouse({
          parent_address_type_id: 3,
          parent_latitude: addressData.latitude,
          parent_longitude: addressData.longitude,
          parent_distance: global.config.proximityDefaultDistance
        });
        if (res && res.warehouses) {
          this.warehouses = res.warehouses;
        }
      } catch (err) {
        console.log(err);
      }

      try {
        // lookup locker
        let res = await AddressService.findWarehouse({
          parent_address_type_id: 5,
          parent_latitude: addressData.latitude,
          parent_longitude: addressData.longitude,
          parent_distance: global.config.proximityDefaultDistance
        });
        if (res && res.warehouses) {
          this.lockers = res.warehouses;
        }
      } catch (err) {
        console.log(err);
      }
      this.results = true;
    },
    getProvince(placeResult) {
      let area = placeResult.address_components.find(p => {
        return includes(p.types, "administrative_area_level_2");
      });
      return area["short_name"];
    },
    async handleAddressCreation(address) {
      if (await this.needLogin("service-autodiscovery")) {
        this.$router.push({
          name: "EditAddress",
          params: {
            newAddress: true,
            discoveredAddress: address
          }
        });
      }
      this.$emit("submit", false);
    },
    async handleWarehouseClick(addressType, addressId) {
      if (await this.needLogin("service-autodiscovery")) {
        await this.setAddress({
          addressType: addressType,
          addressId: addressId
        });
        if (await this.needTimeslot()) {
          this.$router.push({ name: "Home" });
        }
      }
    },
    geolocate() {
      let _this = this;
      if (navigator.geolocation) {
        document.getElementById("map").value = "";
        document.getElementById("map").placeholder =
          "Localizzazione in corso...";
        navigator.geolocation.getCurrentPosition(
          function(position) {
            var latlng = new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: latlng }, function(results, status) {
              if (status == google.maps.GeocoderStatus.OK) {
                var address = GeoService.decodeGoogleAddress(results[0]);
                address.latitude = address.latitude();
                address.longitude = address.longitude();
                var addressFormat = GeoService.composeAddress(address);
                document.getElementById("map").value = addressFormat;
                document.getElementById("map").placeholder =
                  "Scrivi il tuo indirizzo";
                _this.getAddressData(address);
              } else {
                console.log(
                  "Geocode was not successful for the following reason: "
                );
              }
            });
            // var ll = new google.maps.LatLng(
            //   position.coords.latitude,
            //   position.coords.longitude
            // );
            // vm.$refs.address.updateCoordinates(ll);
          },
          function(err) {
            console.log(err);
            alert(
              "è necessario abilitare la localizzazione per utilizzare questa funzione"
            );
          },
          { enableHighAccuracy: false, maximumAge: Infinity }
        );
      }

      // this.$refs.address.geolocate();
    },
    searchFirst() {
      // try get first suggestin google autocomplete
      let vm = this;
      let pacItem = document.getElementsByClassName("pac-item");
      if (pacItem.length > 0) {
        let addressData = null;
        let firstResult = document.getElementsByClassName("pac-item")[0]
          .innerText;
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: firstResult }, function(results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            addressData = results[0];
            vm.addressToSearch = {
              latitude: addressData.geometry.location.lat(),
              longitude: addressData.geometry.location.lng()
            };
            // update search field text and start store locator search
            document.getElementById("map").value =
              addressData.formatted_address;
            vm.getAddressData(addressData);
          } else {
            vm.addressToSearch = null;
          }
        });
      }
    }
  },
  async mounted() {
    try {
      google = await gmapsInit(global.config.google_api_key);
      if (google) {
        this.googleEnabled = true;
        this.$nextTick(() => {
          if (this.additionalData) {
            if (this.additionalData.addressString) {
              this.baseAddressString = this.additionalData.addressString;
            }
            if (
              this.additionalData.addressData &&
              this.additionalData.placeResult
            ) {
              this.getAddressData(
                this.additionalData.addressData,
                this.additionalData.placeResult
              );
            }
          }
        });
      }
    } catch (error) {
      throw new Error("Error Loading GMAPS");
    }
  }
};
</script>
